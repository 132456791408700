const slider = new Swiper('.slider', {
  effect: 'fade',
  loop: true,
  speed: 3000,
  autoplay: {
    delay: 6000,
    disableOnInteraction: false,
  },
});

const pickupSwiper = new Swiper('.pickup-swiper', {
  slidesPerView: 'auto',
  spaceBetween: 24,
  navigation: {
    nextEl: '.pickup-next',
    prevEl: '.pickup-prev',
    disabledClass: 'swiper-button-disabled',
  },
  pagination: {
    el: '.swiper-pagination',
    type: 'progressbar',
  },
  watchOverflow: true,
});
